@import 'styles/variables';
@import 'styles/eeta-page-style';

#bug-bounty {

    &.skeleton-policy {

        .header {
            border-block: 0 !important;
        }

        .tos-body {
            border-top: none !important;
        }

        .text-content:not(:last-child)::after {
            display: none !important;
        }
        .text-content-s{
            height: 100px;
            width: 1000px;
        }
    }

    .styles-text {

        p {
            margin-bottom: 40px !important;
        }
    }

    .skeleton-loading {
        display: flex;
        margin-top: $regularPad;
        background-color: blue !important;

        @media (max-width: $desktop) {
            margin-top: 25px;
        }

        &.title-1 {
            width: 230px;
            margin-left: 410px;
            height: 35px;
            position: relative;
            left: -415px;

            @media (max-width: 768px) {
                margin-top: 100px;
            }
        }

        &.text-1 {
            margin-left: 500px;
            width: 262px;
            height: 24px;

            @media (max-width: 1000px) {
                margin-left: 467px;
            }

            @media (max-width: 755px) {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }
}